import {
  CouponStatusStatus as ReferralCouponStatus,
  DiscountType as ReferralsCouponType,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { LoyaltyCoupon, Status } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import {
  LoyaltyProgram,
  ProgramStatus,
  Status as PointsExpirationStatus,
} from '@wix/ambassador-loyalty-v1-program/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { getTiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { TiersProgram } from '../types/domain';
import { AppInstallStatus } from './getAppInstallStatus';
import { LoadDataResult } from './loadData';

interface CreateFixturesParams {
  flowAPI: ControllerFlowAPI;
  appInstallStatus: AppInstallStatus;
}

export const getDemoContent = async ({ flowAPI, appInstallStatus }: CreateFixturesParams): Promise<LoadDataResult> => {
  const { httpClient, translations, errorHandler } = flowAPI;
  const { t } = translations;
  const { withErrorHandler, getResolvedError } = errorHandler;
  const rewards: Reward[] = [];
  const claimedCoupons: LoyaltyCoupon[] = [];
  const tiersProgram: TiersProgram = {};
  const referralCouponRewards: LoadDataResult['referralCouponRewards'] = [];
  const {
    isLoyaltyAppInstalled,
    isReferralsAppInstalled,
    isStoresAppInstalled,
    isEventsAppInstalled,
    isBookingsAppInstalled,
    isPricingPlansAppInstalled,
    isOldRestaurantsAppInstalled,
    isNewRestaurantsAppInstalled,
  } = appInstallStatus;

  const showLoyaltyFixedRewards =
    isLoyaltyAppInstalled &&
    (isStoresAppInstalled ||
      isEventsAppInstalled ||
      isBookingsAppInstalled ||
      isPricingPlansAppInstalled ||
      isNewRestaurantsAppInstalled);

  const showLoyaltyFlexibleRewards =
    isLoyaltyAppInstalled &&
    (isOldRestaurantsAppInstalled || isNewRestaurantsAppInstalled || isStoresAppInstalled || isBookingsAppInstalled);

  const loyaltyProgramPromise = isLoyaltyAppInstalled
    ? withErrorHandler(() => httpClient.request(getLoyaltyProgram({})), {
        errorCodesMap: {},
      }).catch((error) => {
        throw getResolvedError(error);
      })
    : undefined;

  const tiersProgramSettingsPromise = isLoyaltyAppInstalled
    ? withErrorHandler(() => httpClient.request(getTiersProgramSettings({})), {
        errorCodesMap: {},
      }).catch((error) => {
        throw getResolvedError(error);
      })
    : undefined;

  const [loyaltyProgramResponse, tiersProgramSettingsResponse] = await Promise.all([
    loyaltyProgramPromise,
    tiersProgramSettingsPromise,
  ]);

  const pointsExpirationData = loyaltyProgramResponse?.data?.loyaltyProgram?.pointsExpiration;
  const showPointsExpiration = isLoyaltyAppInstalled && pointsExpirationData?.status === PointsExpirationStatus.ENABLED;

  const loyaltyProgram: LoyaltyProgram = {
    ...loyaltyProgramResponse?.data.loyaltyProgram,
    status: ProgramStatus.ACTIVE,
  };

  const currentDate = new Date();

  const account: LoyaltyAccount = {
    points: {
      balance: 30,
    },
    tier: {
      points: 60,
    },
    ...(showPointsExpiration && {
      pointsExpiration: {
        expiringPointsAmount: 30,
        expirationDate: new Date(
          currentDate.setMonth(currentDate.getMonth() + (pointsExpirationData.monthsOfInactivity ?? 3)),
        ),
      },
    }),
  };

  if (showLoyaltyFlexibleRewards) {
    rewards.push(
      {
        id: '129aff97-f34f-44b7-8aa0-51c4a47668b3',
        name: t('app.fixtures.reward-a.title'),
        active: true,
        discountAmount: {
          configsByTier: [
            {
              costInPoints: 20,
              amount: '15',
            },
          ],
        },
        type: RewardType.DISCOUNT_AMOUNT,
      },
      {
        id: '712fc9d0-6ee6-4da6-a960-690fa617a31f',
        name: t('app.fixtures.reward-b.title'),
        active: true,
        discountAmount: {
          configsByTier: [
            {
              costInPoints: 50,
              amount: '7',
            },
          ],
        },
        type: RewardType.DISCOUNT_AMOUNT,
      },
    );
  }

  if (showLoyaltyFixedRewards) {
    rewards.push(
      {
        id: '5b62a1c8-1906-4a75-bf80-280044446bbb',
        name: t('app.fixtures.reward-d.title'),
        active: true,
        couponReward: {
          fixedAmount: {
            configsByTier: [
              {
                costInPoints: 20,
                amount: 4,
              },
            ],
          },
        },
        type: RewardType.COUPON_REWARD,
        createdDate: new Date(),
      },
      {
        id: '19a42873-02c0-4a26-96ad-eaeeabb85773',
        name: t('app.fixtures.reward-e.title'),
        active: true,
        couponReward: {
          fixedAmount: {
            configsByTier: [
              {
                costInPoints: 50,
                amount: 7,
              },
            ],
          },
        },
        type: RewardType.COUPON_REWARD,
        createdDate: new Date(),
      },
    );

    claimedCoupons.push({
      id: '4e78b097-6c33-497f-b404-703e8bacbee5',
      createdDate: new Date(),
      status: Status.ACTIVE,
      couponReference: {
        code: 'KOW7AF6UVY53',
        specification: {
          moneyOffAmount: 12,
        },
      },
      rewardName: t('app.fixtures.reward-c.title'),
    });
  }

  if (isReferralsAppInstalled) {
    const createdDate = new Date();
    createdDate.setSeconds(createdDate.getSeconds() + 1);

    referralCouponRewards.push({
      id: '1970fbbb-eb5c-469c-9e2c-2bc791129796',
      createdDate,
      coupon: {
        status: ReferralCouponStatus.ACTIVE,
        code: '6JV5990N3XRN',
        couponSpecification: {
          discountType: ReferralsCouponType.PERCENTAGE,
          name: t('app.fixtures.referral-reward.title'),
          scope: {
            namespace: 'stores',
          },
          percentageOptions: {
            percentage: 10,
          },
        },
      },
    });
  }

  if (tiersProgramSettingsResponse?.data) {
    const tiersStatus = tiersProgramSettingsResponse.data.programSettings?.status;

    tiersProgram.programSettings = {
      status: tiersStatus,
      baseTierDefinition: {
        name: t('app.fixtures.tier-basic.title'),
        icon: {
          url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
        },
      },
    };

    tiersProgram.tiers = [
      {
        id: 'fa4d72b2-096f-4fc7-89c4-d26163a4674a',
        tierDefinition: {
          name: t('app.fixtures.tier-silver.title'),
          icon: {
            url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
          },
        },
        requiredPoints: 100,
      },
    ];
  }

  return {
    loyaltyProgram,
    account,
    rewards,
    claimedCoupons,
    referralCouponRewards,
    couponEntityNames: {},
    tiersProgram,
  };
};
