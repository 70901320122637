import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';

import { PageLinks, ThunkApiConfig } from '../../../../../../types';
import { navigateToUrl } from '../../../../../../utils';

export interface NavigationProps {
  pageLinks: PageLinks;
}

const initialState: NavigationProps = {
  pageLinks: {
    loyaltyPageHref: '',
    loyaltyPageUrl: '',
    applyRewardHref: '',
    applyRewardUrl: '',
  },
};

const navigateToLoyaltyPage = createAsyncThunk<void, void, ThunkApiConfig>(
  'navigation/navigateToLoyaltyPage',
  async (_, { extra, getState, rejectWithValue }) => {
    const { flowAPI } = extra;
    const { navigationConfig } = getState();

    try {
      if (flowAPI.environment.isViewer) {
        flowAPI.bi?.report(
          loyaltyUouClick({
            value: 'learn_how_to_earn_points',
          }),
        );
      }

      navigateToUrl(flowAPI, navigationConfig.pageLinks.loyaltyPageUrl);
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(navigateToLoyaltyPage.fulfilled, () => {}).addCase(navigateToLoyaltyPage.rejected, () => {});
  },
});

export const navigationThunk = { navigateToLoyaltyPage };
