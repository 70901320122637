import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '../../../../../../constants';

export interface LocaleProps {
  locale: string;
  timeZone: string;
}

const initialState: LocaleProps = {
  locale: DEFAULT_LOCALE,
  timeZone: DEFAULT_TIMEZONE,
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {},
});
