import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ToastType } from '../../../../../../constants';

export interface ToastConfigProps {
  type: ToastType;
  message: string;
  isVisible: boolean;
}

const initialState: ToastConfigProps = {
  type: ToastType.Success,
  message: '',
  isVisible: false,
};

type ShowToastParams = Pick<ToastConfigProps, 'type' | 'message'>;

const showToast = createAsyncThunk<ShowToastParams, ShowToastParams>(
  'toast/showToast',
  async (params, { dispatch }) => {
    dispatch(toastSlice.actions.hideToast());
    return params;
  },
);

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    hideToast: (state) => {
      state.isVisible = false;
      state.type = ToastType.Success;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showToast.fulfilled, (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.isVisible = true;
    });
  },
});

export const toastThunk = { showToast };
