import {
  WIX_BOOKINGS,
  WIX_RESTAURANTS_ORDERS as APP_ID_RESTAURANTS_ORDERS_OLD,
  WIX_RESTAURANTS_ORDERS_NEW as APP_ID_RESTAURANTS_ORDERS_NEW,
} from '@wix/app-definition-ids';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { LOYALTY_APP_DEFINITION_ID, LOYALTY_APP_SECTION_ID } from '../constants';
import { AppInstallStatus } from './getAppInstallStatus';

const WIDGET_ID_RESTAURANTS_ORDERS_OLD = '96254d42-7323-40cb-a7cb-b7c242019728';
const WIDGET_ID_RESTAURANTS_ORDERS_NEW = '5077609c-e47c-4da6-8147-008d243e4499';
const WIDGET_ID_BOOKINGS = '621bc837-5943-4c76-a7ce-a0e38185301f';

export interface PageLinks {
  loyaltyPageHref: string;
  loyaltyPageUrl: string;
  applyRewardHref: string;
  applyRewardUrl: string;
}

interface Params {
  flowAPI: ControllerFlowAPI;
  appInstallStatus: AppInstallStatus;
}

export const getPageLinks = async ({ flowAPI, appInstallStatus }: Params): Promise<PageLinks> => {
  const { isOldRestaurantsAppInstalled, isNewRestaurantsAppInstalled, isStoresAppInstalled, isBookingsAppInstalled } =
    appInstallStatus;

  const [homePageLink, loyaltyPageLink, oldRestaurantsPageLink, newRestaurantsPageLink, bookingsPageLink] =
    await Promise.all([
      getHomePageLink(flowAPI),
      getLoyaltyPageLink(flowAPI),
      getOldRestaurantsPageLink(flowAPI),
      getNewRestaurantsPageLink(flowAPI),
      getBookingsPageLink(flowAPI),
    ]);

  let applyRewardHref = homePageLink.href;
  let applyRewardUrl = homePageLink.url;

  if (isOldRestaurantsAppInstalled) {
    applyRewardHref = oldRestaurantsPageLink.href;
    applyRewardUrl = oldRestaurantsPageLink.url;
  } else if (!isStoresAppInstalled) {
    if (isNewRestaurantsAppInstalled) {
      applyRewardHref = newRestaurantsPageLink.href;
      applyRewardUrl = newRestaurantsPageLink.url;
    } else if (isBookingsAppInstalled) {
      applyRewardHref = bookingsPageLink.href;
      applyRewardUrl = bookingsPageLink.url;
    }
  }

  return {
    loyaltyPageHref: loyaltyPageLink.href,
    loyaltyPageUrl: loyaltyPageLink.url,
    applyRewardHref,
    applyRewardUrl,
  };
};

interface Link {
  href: string;
  url: string;
}

const getHomePageLink = async (flowAPI: ControllerFlowAPI): Promise<Link> => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { pages } = await wixCodeApi.site.getSiteStructure({ includePageId: true });
  const homePage = pages.find(({ isHomePage }) => isHomePage);

  const href = wixCodeApi.location.baseUrl ?? '';
  const url = homePage?.url ?? '';

  return { href, url };
};

interface AppSectionParams {
  flowAPI: ControllerFlowAPI;
  appDefinitionId: string;
  sectionId: string;
}

const getAppSectionUrl = async ({ flowAPI, appDefinitionId, sectionId }: AppSectionParams) => {
  const { wixCodeApi } = flowAPI.controllerConfig;

  try {
    return await wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
  } catch {}
};

const getAppPageLink = async ({ flowAPI, appDefinitionId, sectionId }: AppSectionParams): Promise<Link> => {
  const sectionUrl = await getAppSectionUrl({
    flowAPI,
    appDefinitionId,
    sectionId,
  });

  const href = sectionUrl?.url ?? '';
  const url = sectionUrl?.relativeUrl ?? '';

  return { href, url };
};

const getLoyaltyPageLink = async (flowAPI: ControllerFlowAPI) => {
  return getAppPageLink({
    flowAPI,
    appDefinitionId: LOYALTY_APP_DEFINITION_ID,
    sectionId: LOYALTY_APP_SECTION_ID,
  });
};

const getOldRestaurantsPageLink = async (flowAPI: ControllerFlowAPI) => {
  return getAppPageLink({
    flowAPI,
    appDefinitionId: APP_ID_RESTAURANTS_ORDERS_OLD,
    sectionId: WIDGET_ID_RESTAURANTS_ORDERS_OLD,
  });
};

const getNewRestaurantsPageLink = async (flowAPI: ControllerFlowAPI) => {
  return getAppPageLink({
    flowAPI,
    appDefinitionId: APP_ID_RESTAURANTS_ORDERS_NEW,
    sectionId: WIDGET_ID_RESTAURANTS_ORDERS_NEW,
  });
};

const getBookingsPageLink = async (flowAPI: ControllerFlowAPI) => {
  return getAppPageLink({
    flowAPI,
    appDefinitionId: WIX_BOOKINGS,
    sectionId: WIDGET_ID_BOOKINGS,
  });
};
